.inputbox {
  width: 160px;
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 6px;
  margin-bottom: 16px;
  border: 1px solid #d8e0f0;
  padding: 10px;
  outline: none;
}

.sizedinput {
  width: 120px;
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 6px;
  margin-bottom: 16px;
  border: 1px solid #d8e0f0;
  padding: 10px;
  outline: none;
}
.sminput {
  width: 100px;
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 6px;
  margin-bottom: 16px;
  border: 1px solid #d8e0f0;
  padding: 10px;
  outline: none;
}
.textbox {
  width: 80px;
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 6px;
  margin-bottom: 16px;
  border: 1px solid #d8e0f0;
  padding: 10px;
  outline: none;
}
.datepicker {
  width: 130px;
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 6px;
  margin-bottom: 16px;
  border: 1px solid #d8e0f0;
  padding: 10px;
  outline: none;
}

.datetimepicker {
  width: 160px;
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 6px;
  margin-bottom: 16px;
  border: 1px solid #d8e0f0;
  padding: 10px;
  outline: none;
}
.datetimepicker svg {
  height: 20px;
  width: 20px;
  margin-top: 20px;
}
.checkbox {
  width: 16px;
  height: 16px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 6px;
  margin-bottom: 16px;
  border: 1px solid #d8e0f0;
  padding: 10px;
  outline: none;
}
.radiobox {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.radioboxGroup {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
